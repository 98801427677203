import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'fixedData';


// Delete or alt status data
export function Reg_AltStatus(userId, origin, type, id, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('id', id);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url : process.env.REACT_APP_API_URL + 'email/recover_access.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        switch (response.data) {
            case "Email successfully sent": CallbackSuccess(); break;
            case "Error sending message": CallbackError_Send(); break;
            case "Email not sent": CallbackError_NotEmail(); break;
            case "Email not found": CallbackError(); break;
            default: CallbackError(); break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url : process.env.REACT_APP_API_URL + 'email/recover_access.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        switch (response.data) {
            case "Email successfully sent": case "connected":
                    CallbackSuccess(); 
                break;
            default: 
                    CallbackError(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Checked Data
export function CheckedData(edit_data, start, hour, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'checked_data');
    data.append('edit_data', edit_data);
    data.append('start', start);
    data.append('hour', hour);

    Axios({
        url : process.env.REACT_APP_API_URL + 'inc/checked_data.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        switch (response.data) {
            case "denied":
                    CallbackError();                
                break;
            case "released":
                    CallbackSuccess();                
                break;
        }
        

    }).catch((error)=>{
        CallbackError();
    })
}


// AccessDash
export function Reg_AccessDash(userId, id, name, email, password, file, nivel, note, page, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('password', password);
    data.append('file', file);
    data.append('nivel', nivel.value);
    data.append('note', note);

    // page
    if(nivel.value == 0){
        page.forEach((elem, index) => {
            data.append('page_id[]', elem.id);
            data.append('page_order_[]', elem.order_);
            data.append('page_type[]', elem.type);
            data.append('page_page[]', elem.page);
            data.append('page_name[]', elem.name);
        });
    }

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/access_dash.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        SetListPag('currentPageId', response.data.idPage);

        if(userId == id){
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 3);
    
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);

            if(response.data.pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }            

            SetUserData('file', response.data.file);
            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }

        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Client
export function Reg_Client(userId, id, name, email, password, file, note, complement, phone, cep, address, street, state, city, neighborhood, number, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'client');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('password', password);
    data.append('file', file);
    data.append('note', note);
    data.append('complement', complement);
    data.append('phone', phone);
    data.append('cep', cep);
    data.append('address', address);
    data.append('street', street);
    data.append('state', state);
    data.append('city', city);
    data.append('neighborhood', neighborhood);
    data.append('number', number);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/client.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('client', response.data.client);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}