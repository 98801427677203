import { useState, useEffect } from "react";

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Svg_ArrowRight, Svg_Dashboard, Svg_Edit, Svg_Exit, Svg_MenuHamburguer, Svg_Site, Svg_User, Svg_UserDash } from 'services/SvgFile';

import { colorIconSecondary, cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw } from "fixedData";

import Page_Details from "pages/Details";

import Page_AccessDash from "pages/AccessDash";
import Page_AccessDash_Details from "pages/AccessDash/Details";

import Page_Client from "pages/Client";
import Page_Client_Details from "pages/Client/Details";

export default function Contents(props){

    const cookies = new Cookies();
    const [ statusAll, setStatusAll ] = useState(false);
    const [ statusMenu, setStatusMenu ] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ userFile, setUserFile ] = useState(GetUserData('file'));
    const [ userName, setUserName ] = useState(GetUserData('name'));
    const [ userNivel, setUserNivel ] = useState(GetUserData('nivel'));
    const [ userPage, setUserPage ] = useState(GetUserData('page'));

    function CheckedPage(value){
        SetListPag('currentPage', value);
    }

    function OpenPage(value, id){
        SetListPag('currentPage', value);
        SetListPag('currentPageId', id);
    }

    function CkickPage(value){
        if(value != currentPage){
            if(statusAll){
                SetModalData('ReturnResponse', { "page": "altStatus", "CkickPage": CheckedPage, "name_page": value, "setStatusAll": setStatusAll, "text": 'Houve alteração de dados, deseja mudar de página?' });
                SetModalState('ReturnResponse', true);
            }else {
                SetListPag('currentPage', value);
            }
        }else {
            SetListPag('currentPage', value);
        }
        CloseMenu();
    }

    function CurrentPage(){
        switch(currentPage) {
            case "access_dash":
                return <Page_AccessDash setLoading={ props.setLoading } OpenFile={ props.OpenFile } setStatusAll={ setStatusAll } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } />;
            case "access_dash_details":
                return <Page_AccessDash_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } setStatusAll={ setStatusAll } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } />;

            case "client":
                return <Page_Client setLoading={ props.setLoading } OpenFile={ props.OpenFile } setStatusAll={ setStatusAll } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } />;
            case "client_details":
                return <Page_Client_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } setStatusAll={ setStatusAll } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } />;

            default: 
                return <Page_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } setStatusAll={ setStatusAll } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    function CloseMenu(){
        if(document.body.clientWidth <= 580){
            setStatusMenu(false);
        }
    }

    function CheckedIcon(){
        if(document.body.clientWidth <= 580){            
            return <Svg_MenuHamburguer className={ statusMenu ? "icons" : "icons icon_close" } color="#2d3c53" />;
        }else {
            return <Svg_ArrowRight className={ statusMenu ? "icons" : "icons icon_close" } color="#2d3c53" />;
        }
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('nivel', setUserNivel);
        RegisterUserData('page', setUserPage);
        
        if(document.body.clientWidth <= 1024){
            setStatusMenu(true);
        }
        if(document.body.clientWidth <= 580){
            setStatusMenu(false);
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1024){
                setStatusMenu(true);
            }
            if(window.innerWidth <= 580){
                CheckedIcon();
                setStatusMenu(false);
            }
        });
    }, []);

    return(
        <div className="Contents">
            <div className={ statusMenu ? "div_menu_opt menu_close" : "div_menu_opt" }>
                <div className={ statusMenu ? "div_logo div_logo_alt_width" : "div_logo" }>
                    <div className={ statusMenu ? "open_or_close_menu alt_position_icons" : "open_or_close_menu" } onClick={ ()=>{ setStatusMenu(!statusMenu) } }>
                        {
                            CheckedIcon()
                        }
                    </div>
                    
                    <img alt="logotipo" src="./assets/logo.png" className={ statusMenu ? "logotipo logo_hide" : "logotipo" } />
                </div>

                <div className={ statusMenu ? "show_menu show_menu_alt_width" : "show_menu" }>
                    
                    <div className={ currentPage == "index" || currentPage == "index_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("index"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Dashboard className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Dashboard</div>
                        </div>
                    </div>
                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_UserDash className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Acesso da dashboard</div>
                        </div>
                    </div>

                    <div className={ currentPage == "client" || currentPage == "client_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("client"); } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_UserDash className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Clientes</div>
                        </div>
                    </div>

                    <div className={ statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_Exit className="icons_dash" color={ colorIconSecondary } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Sair</div>
                        </div>
                    </div>
                </div> 

                <div className={ statusMenu ? "div_inf_profile div_inf_profile_alt_width" : "div_inf_profile" }>
                    <div className="show_name_user">
                        <div className="div_img">
                            {
                                userFile !='' ?
                                <img alt="profile user" src={ userFile } className="show_img_profile" /> :
                                <Svg_User color="#FFFFFF" className="show_img_profile" />
                            }
                        </div>
                        <div className={ statusMenu ? "name_user title_close" : "name_user" }>
                            { userName.split(' ')[0] }
                            {
                                userName.split(' ')[1] ? " " + userName.split(' ')[1] : null
                            }
                        </div>
                        
                        <div className={ statusMenu ? "title_close" : "" }>
                            <Svg_Edit className="icons" color="#ffffff" />
                        </div> 
                       
                    </div>
                </div>               
            </div>

            <div className="div_data_page">
                {
                    CurrentPage()
                }
            </div>
        </div>
    )
}