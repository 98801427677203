import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_AccessDash } from "services/Register";
import { Svg_ChekedReturn, Svg_Close } from "services/SvgFile";

export default function Page_AccessDash_Details(props){

    const [ listPage, setListPage ] = useState([
        { "id": 0, "order_": 0, "type": "hide_page", "page": "site", "name": "Site" },
        { "id": 0, "order_": 1, "type": "hide_page", "page": "banner", "name": "Banner" },
        { "id": 0, "order_": 1, "type": "hide_page", "page": "category", "name": "Categorias" },
        { "id": 0, "order_": 2, "type": "hide_page", "page": "product", "name": "Produtos" },
        { "id": 0, "order_": 3, "type": "hide_page", "page": "services", "name": "Serviços" },
        { "id": 0, "order_": 4, "type": "hide_page", "page": "brands", "name": "Marcas" },
        { "id": 0, "order_": 5, "type": "hide_page", "page": "faq", "name": "Faq" }
    ])

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_dash'));

    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ password, setPassword ] = useState('');
    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ thumbnail, setThumbnail ] = useState(InitialData('thumbnail'));
    const [ nivel, setNivel ] = useState(InitialData('nivel'));
    const [ note, setNote ] = useState(InitialData('note'));
    const [ page, setPage ] = useState(InitialData('page'));

    function InitialData(type){
        const newData = GetDataPage('access_dash').find(item => item.id == idPage);
        if(newData){
            if(type === 'page'){
                if(newData[type].length > 0){
                    return newData[type];
                }else {
                    return listPage;
                }
            }else {
                return newData[type];
            }
        }
        if(type === 'page'){
            return listPage;
        }
        return '';
    }

    function HandleData(index, value){
        const newData = [...page];
        newData[index]['type'] = value;
        setPage(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);        
        Reg_AccessDash(props.userId, idPage, name, email, password, file, nivel, note, page, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setDataPage);
        return ()=>{
            UnRegisterDataPage('access_dash', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'))
        setDataPage(GetDataPage('access_dash'));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setPassword('');
        setFile(InitialData('file'));
        setFileStatus(false);
        setThumbnail(InitialData('thumbnail'));
        setNivel(InitialData('nivel'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
    }, [dataPage, idPage]);

    return(
        <form className="div_show_data_access div_inf_page show_page" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Detalhes do acesso administrativo" PageClick={ props.OpenPage } page="access_dash" idPage={ 0 } />

            <div className="show_data_page">
                <div className="div_opt_data">Dados do acesso</div>
                <div className="show_content_data">
                    <Comp_Input index="not" type="text" name="Nome" className="" setValue={ setName } value={ name } />

                    <Comp_Input index="not" type="text" name="E-mail" className="" setValue={ setEmail } value={ email } />

                    <Comp_Input index="not" type="password" name="Senha" className="inf_input" setValue={ setPassword } value={ password } maxLength={ 10 } placeholder="****" />
                    {
                        idPage == props.userId ? null :
                        <Comp_Select index="not" name="Tipo de acesso" className="type_access" opt={ [{ "value": 0, "label": "Usuário" }, { "value": 1, "label": "Administrador" }] } setValue={ setNivel } value={ nivel } />
                    }
                    
                    <Comp_InputFile index="not" setValue={ setFile } value={ file } setStatus={ setFileStatus } status={ fileStatus } OpenFile={ props.OpenFile } thumbnail={ thumbnail } />
                </div>

                <div className="show_content_data">
                    <Comp_Textarea index="not" name="Observação" className="" setValue={ setNote } value={ note } /> 
                </div>
            </div>

            {/* 
            {
                nivel.value == 0 ?
                    <div className="show_data_page">
                        <div className="div_opt_data">
                            <div className="">Acesso as paginas</div>
                        </div>
                        <div className="list_data_table">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th>Nome da página</th>
                                        <th width="120">Tipo de acesso</th>
                                        <th width="20" align="center">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {                                        
                                        page.map((elem, index)=>{
                                            return(
                                                <tr className="show_page_access" key={ index }>
                                                    <td className="">{ index + 1 }</td>
                                                    <td className="">{ elem.name }</td>
                                                    <td className="">
                                                        <select className="" onChange={ (e)=>{ HandleData(index, e.target.value) } } value={ elem.type }>
                                                            <option value="hide_page">Sem acesso</option>
                                                            <option value="edit_page">Pode editar</option>
                                                        </select>
                                                    </td>
                                                    <td align="center">
                                                        {
                                                            elem.type === "hide_page" ?
                                                            <Svg_Close className="icons" color="#f00000" />
                                                            :
                                                            <Svg_ChekedReturn className="icons" color="#0e8d2e" />
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }   
                                </tbody>
                            </table>
                        </div>
                    </div>
                : null
            } 
             */}
        </form>
    )
}