
// Cookies folder project
// export const cookiesRegister = './';
// export const urlPhp = 'http://localhost/React_JS/Marcelo/dash_and_site_dynamic/Dashboard_Admin/public/php/';

export const cookiesRegister = 'clicksite-admin.fastevolution.com.br/';
export const urlPhp = 'https://clicksite-admin.fastevolution.com.br/php/';

// Cookies name
export const typeCookie = 'clickadmin_dashboard';
export const typeCookiePassw = 'clickadmin_passw';
export const typeCookieEmail = 'clickadmin_email';
export const typeCookiePage = 'clickadmin_page';
export const typeCookiePageId = 'clickadmin_page_id';

export const colorIcon = '#2d3c53';
export const colorIconSecondary = '#ffc400';

// opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['bold', 'italic'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "",
    toolbarAdaptive: false
}