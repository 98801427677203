import { useState, useEffect } from "react";

import './Details.css';

export default function Page_Details(props){

    return(
        <div className="div_inf_page inf_dashboard">
        </div>
    )
}